import { combineReducers } from "redux"
import searchPatientsReducer from "./search"
import userReducer from "./user"
import pmaIssuesReducer, { PmaIssuesState } from "./issues/reducer"
import eventLogReducer, { EventLogState } from "./eventLog/reducer"
import { SearchState } from "./search/reducer"
import { UserState } from "./user/reducer"
import updatePatientConditionStateReducer, {
	UpdatePatientConditionState
} from "./updateCondition/reducer"
import changeConditionReducer, {
	ChangeConditionState
} from "./changeCondition/reducer"
import nhsNumberImportReducer, {
	NhsNumberImportState
} from "./nhsNumber/reducer"

export interface PatientsState {
	updateCondition: UpdatePatientConditionState
	changeCondition: ChangeConditionState
	search: SearchState
	nhsNumber: NhsNumberImportState
	user: UserState
	issues: PmaIssuesState
	eventLog: EventLogState
}

const patientsReducer = combineReducers({
	updateCondition: updatePatientConditionStateReducer,
	changeCondition: changeConditionReducer,
	search: searchPatientsReducer,
	nhsNumber: nhsNumberImportReducer,
	user: userReducer,
	issues: pmaIssuesReducer,
	eventLog: eventLogReducer
})

export default patientsReducer
