export const EXTRACTS_CIRCLE_MISSING_TENANT_PATIENT_ID_EXTRACT_REQUEST =
	"EXTRACTS_CIRCLE_MISSING_TENANT_PATIENT_ID_EXTRACT_REQUEST"
export const EXTRACTS_CIRCLE_MISSING_TENANT_PATIENT_ID_EXTRACT_RECEIVE =
	"EXTRACTS_CIRCLE_MISSING_TENANT_PATIENT_ID_EXTRACT_RECEIVE"
export const EXTRACTS_CIRCLE_MISSING_TENANT_PATIENT_ID_EXTRACT_FAILURE =
	"EXTRACTS_CIRCLE_MISSING_TENANT_PATIENT_ID_EXTRACT_FAILURE"

export const EXTRACTS_CIRCLE_UPLOAD_MISSING_TENANT_PATIENT_ID_REQUEST =
	"EXTRACTS_CIRCLE_UPLOAD_MISSING_TENANT_PATIENT_ID_REQUEST"
export const EXTRACTS_CIRCLE_UPLOAD_MISSING_TENANT_PATIENT_ID_RECEIVE =
	"EXTRACTS_CIRCLE_UPLOAD_MISSING_TENANT_PATIENT_ID_RECEIVE"
export const EXTRACTS_CIRCLE_UPLOAD_MISSING_TENANT_PATIENT_ID_FAILURE =
	"EXTRACTS_CIRCLE_UPLOAD_MISSING_TENANT_PATIENT_ID_FAILURE"
