export const EXTRACTS_TLC_EPISODENUMBER_DOWNLOAD_REQUEST =
	"EXTRACTS_TLC_EPISODENUMBER_DOWNLOAD_REQUEST"
export const EXTRACTS_TLC_EPISODENUMBER_DOWNLOAD_RECEIVE =
	"EXTRACTS_TLC_EPISODENUMBER_DOWNLOAD_RECEIVE"
export const EXTRACTS_TLC_EPISODENUMBER_DOWNLOAD_FAILURE =
	"EXTRACTS_TLC_EPISODENUMBER_DOWNLOAD_FAILURE"

export const EXTRACTS_TLC_EPISODENUMBER_UPLOAD_REQUEST =
	"EXTRACTS_TLC_EPISODENUMBER_UPLOAD_REQUEST"
export const EXTRACTS_TLC_EPISODENUMBER_UPLOAD_RECEIVE =
	"EXTRACTS_TLC_EPISODENUMBER_UPLOAD_RECEIVE"
export const EXTRACTS_TLC_EPISODENUMBER_UPLOAD_FAILURE =
	"EXTRACTS_TLC_EPISODENUMBER_UPLOAD_FAILURE"
