import React, { Component } from "react"

interface ErrorBoundaryState {
	hasError: boolean
}

interface ErrorBoundaryProps {
	children: React.ReactNode
	errorPage: () => JSX.Element
}

// Error boundaries must be class components - https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error: any) {
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: Object): void {
		this.setState({ hasError: true })
	}

	render() {
		if (this.state.hasError) {
			return this.props.errorPage()
		}

		return this.props.children
	}
}

export default ErrorBoundary
