import * as React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
	faTachometerAlt,
	faUserMd,
	faUserPlus,
	faHospitalAlt,
	faUpload,
	faBan,
	faShare,
	faShareAlt,
	faProcedures,
	faUsers,
	faClipboardList,
	faUserNinja,
	faFileExport,
	faFileImport,
	faSpinner,
	faKey,
	faCheckSquare,
	faChevronRight,
	faChevronLeft,
	faChevronDown,
	faPlusSquare,
	faSquare,
	faMinusSquare,
	faFolder,
	faFolderOpen,
	faFile,
	faLock,
	faLockOpen,
	faUserSecret,
	faBomb,
	faEye,
	faEyeSlash,
	faExclamationTriangle,
	faListAlt,
	faQuestionCircle,
	faEnvelopeOpenText,
	faHistory,
	faHospitalUser,
	faCheck,
	faBriefcaseArrowRight,
	faSquareEllipsisVertical,
	faBoxesPacking
} from "@fortawesome/pro-solid-svg-icons"
import {
	faUserRobot,
	faSms,
	faFolderMagnifyingGlass
} from "@fortawesome/pro-solid-svg-icons"
import {
	faCopy,
	faCheckSquare as farCheckSquare,
	faSquare as farSquare
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

library.add(
	faTachometerAlt,
	faUserMd,
	faUserPlus,
	faHospitalAlt,
	faUpload,
	faBan,
	faShare,
	faShareAlt,
	faProcedures,
	faUsers,
	faClipboardList,
	faUserNinja,
	faFileExport,
	faSpinner,
	faKey,
	faCheckSquare,
	faChevronRight,
	faChevronLeft,
	faChevronDown,
	faPlusSquare,
	faSquare,
	faMinusSquare,
	faFolder,
	faFolderOpen,
	faFile,
	faLock,
	faLockOpen,
	faUserSecret,
	faCopy,
	faEye,
	faEyeSlash,
	farCheckSquare,
	farSquare,
	faBan,
	faBomb,
	faExclamationTriangle,
	faListAlt,
	faQuestionCircle,
	faEnvelopeOpenText,
	faHistory,
	faHospitalUser,
	faUserRobot,
	faCheck,
	faFolderMagnifyingGlass,
	faBriefcaseArrowRight,
	faSquareEllipsisVertical,
	faBoxesPacking
)
export default class Icons {
	static Check = faCheck
	static ListAlt = faListAlt
	static TachometerAlt = faTachometerAlt
	static UserMd = faUserMd
	static HospitalAlt = faHospitalAlt
	static UserPlus = faUserPlus
	static Upload = faUpload
	static Ban = faBan
	static BoxesPacking = faBoxesPacking
	static Share = faShare
	static ShareAlt = faShareAlt
	static Procedures = faProcedures
	static Users = faUsers
	static ClipboardList = faClipboardList
	static UserNinja = faUserNinja
	static FileExport = faFileExport
	static FileImport = faFileImport
	static Spinner = faSpinner
	static Key = faKey
	static Lock = faLock
	static LockOpen = faLockOpen
	static Bomb = faBomb
	static ChevronDown = faChevronDown
	static ChevronRight = faChevronRight
	static ChevronLeft = faChevronLeft
	static UserSecret = faUserSecret
	static Eye = faEye
	static EyeSlash = faEyeSlash
	static Copy = faCopy
	static ExclamationTriangle = faExclamationTriangle
	static QuestionCircle = faQuestionCircle
	static History = faHistory
	static HospitalUser = faHospitalUser
	static UserRobot = faUserRobot
	static FolderSearch = faFolderMagnifyingGlass
	static BriefcaseArrowRight = faBriefcaseArrowRight
	static CheckBoxTreeIcons = {
		check: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-check"
				icon={farCheckSquare}
			/>
		),
		uncheck: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-uncheck"
				icon={farSquare}
			/>
		),
		halfCheck: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-half-check"
				icon={farCheckSquare}
			/>
		),
		expandClose: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-expand-close"
				icon="chevron-right"
			/>
		),
		expandOpen: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-expand-open"
				icon="chevron-down"
			/>
		),
		expandAll: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-expand-all"
				icon="plus-square"
			/>
		),
		collapseAll: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-collapse-all"
				icon="minus-square"
			/>
		),
		parentClose: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-parent-close"
				icon="folder"
			/>
		),
		parentOpen: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-parent-open"
				icon="folder-open"
			/>
		),
		leaf: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-leaf-close"
				icon="file"
			/>
		),
		disabled: (
			<FontAwesomeIcon
				className="rct-icon rct-icon-leaf-close text-danger"
				icon={faBan}
			/>
		)
	}
	static EnvelopeOpenText = faEnvelopeOpenText
	static Sms = faSms
	static Queue = faSquareEllipsisVertical
}
