import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import ExtractState from "../../../../models/ExtractState"

const initialSpireWorsenedPatients: ExtractState = {
	downloadInProgress: false,
	sendToSftpInProgress: false
}

function spireWorsenedPatientsReducer(
	state: ExtractState = initialSpireWorsenedPatients,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_SPIRE_WORSENED_PATIENTS_SEND_TO_SFTP_REQUEST:
			return {
				...state,
				sendToSftpInProgress: true
			}
		case ActionTypes.EXTRACTS_SPIRE_WORSENED_PATIENTS_SEND_TO_SFTP_RECEIVE:
			toast.success("Spire Worsened Patients extract sent to SFTP")
			return {
				...state,
				sendToSftpInProgress: false
			}
		case ActionTypes.EXTRACTS_SPIRE_WORSENED_PATIENTS_SEND_TO_SFTP_FAILURE:
			toast.error(
				"Unable to send Spire Worsened Patients extract to SFTP"
			)
			return {
				...state,
				sendToSftpInProgress: false
			}
	}
	return state
}

export default spireWorsenedPatientsReducer
