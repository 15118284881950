import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import ExtractState from "../../../../models/ExtractState"

const initial: ExtractState = {
	downloadInProgress: false
}

function adhocUriExtractReducer(
	state: ExtractState = initial,
	action: any
): ExtractState {
	switch (action.type) {
		case ActionTypes.URI_EXTRACT_ADHOC_REQUEST:
			return {
				...state,
				sendToSftpInProgress: true
			}
		case ActionTypes.URI_EXTRACT_ADHOC_RECEIVE:
			toast.success("URI extract has started. Follow progress on slack")
			return {
				...state,
				sendToSftpInProgress: false
			}
		case ActionTypes.URI_EXTRACT_ADHOC_FAILURE:
			const message = "Unable to start ad hoc uri extract"
			toast.error(message)
			console.error(message, action.payload)
			return {
				...state,
				sendToSftpInProgress: false
			}
	}
	return state
}

export default adhocUriExtractReducer
