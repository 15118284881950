import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import { ProfessionalUser } from "../../../models/User"

export interface SharedUser {
	name: string
	ownerUserId: string
	targetUserId: string
	email: string
	isConsultant: boolean
	treatmentCenters: {
		treatmentCenterId: number
		name: string
	}[]
}

export interface UserState {
	loadingUser: boolean
	professional: ProfessionalUser
	sharedUsers: SharedUser[]
	loadingShares: boolean
	sharedFromUsers: SharedUser[]
}

const initialUser: UserState = {
	loadingUser: false,
	professional: {} as ProfessionalUser,
	sharedUsers: [],
	loadingShares: false,
	sharedFromUsers: []
}

function userReducer(state: UserState = initialUser, action: any) {
	switch (action.type) {
		case ActionTypes.PROFESSIONAL_DETAILS_REQUEST:
			return {
				...state,
				professional: {},
				loadingUser: true
			}
		case ActionTypes.PROFESSIONAL_DETAILS_RECEIVE:
			return {
				...state,
				sharedUsers: [],
				professional: {
					...action.payload,
					gmcNumber: action.payload.gmcNumber || ""
				},
				loadingUser: false
			}
		case ActionTypes.PROFESSIONAL_DETAILS_FAILURE:
			toast.error("Unable to load professional details")
			return {
				...state,
				professional: {},
				sharedUsers: [],
				loadingUser: false
			}
		case ActionTypes.USER_CLEAR:
			return { ...initialUser }
		case ActionTypes.PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_RECEIVE:
			toast.success("Medical areas successfully updated")
			return {
				...state,
				professional: action.payload
			}
		case ActionTypes.PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_FAILURE:
			toast.error("Medical areas update failed")
			return state
		case ActionTypes.PROFESSIONAL_LOCK_RECEIVE:
			toast.success("Successfully locked user")
			return {
				...state,
				professional: action.payload
			}
		case ActionTypes.PROFESSIONAL_LOCK_FAILURE:
			toast.error("Unable to lock user")
			return state
		case ActionTypes.PROFESSIONAL_UNLOCK_RECEIVE:
			toast.success("Successfully unlocked user")
			return {
				...state,
				professional: action.payload
			}
		case ActionTypes.PROFESSIONAL_UNLOCK_FAILURE:
			toast.error("Unable to unlock user")
			return state
		case ActionTypes.CONSULTANT_DELETE_RECEIVE:
			toast.success("Successfully deleted user")
			return state
		case ActionTypes.CONSULTANT_DELETE_FAILURE:
			toast.error("Unable to delete user")
			return state
		case ActionTypes.TEAMMEMBER_DELETE_RECEIVE:
			toast.success("Successfully deleted user")
			return {
				...state,
				professional: {},
				sharedUsers: []
			}
		case ActionTypes.TEAMMEMBER_DELETE_FAILURE:
			toast.error("Unable to delete user")
			return state
		case ActionTypes.PROFESSIONAL_RESTORE_RECEIVE:
			toast.success("Successfully restored user")
			return {
				...state,
				professional: action.payload
			}
		case ActionTypes.PROFESSIONAL_RESTORE_FAILURE:
			toast.error("Unable to restore user")
			return state
		case ActionTypes.PROFESSIONAL_SHAREDFROM_SHARING_REQUEST:
			return {
				...state,
				loadingShares: true
			}
		case ActionTypes.PROFESSIONAL_SHAREDFROM_SHARING_RECEIVE:
			return {
				...state,
				sharedFromUsers: action.payload.sharedUsers,
				loadingShares: false
			}
		case ActionTypes.PROFESSIONAL_SHAREDFROM_SHARING_FAILURE:
			toast.error("Unable to load shared users")
			return {
				...state,
				sharedFromUsers: [],
				loadingShares: false
			}
		case ActionTypes.PROFESSIONAL_SHAREDTO_SHARING_REQUEST:
			return {
				...state,
				loadingShares: true
			}
		case ActionTypes.PROFESSIONAL_SHAREDTO_SHARING_RECEIVE:
			return {
				...state,
				sharedUsers: action.payload.sharedUsers,
				loadingShares: false
			}
		case ActionTypes.PROFESSIONAL_SHAREDTO_SHARING_FAILURE:
			toast.error("Unable to load shared users")
			return {
				...state,
				sharedUsers: [],
				loadingShares: false
			}
		case ActionTypes.PROFESSIONAL_TREATMENT_CENTERS_UPDATE_RECEIVE:
			toast.success("Successfully updated treatment centres")
			return {
				...state,
				professional: action.payload
			}
		case ActionTypes.PROFESSIONAL_TREATMENT_CENTERS_UPDATE_FAILURE:
			toast.error("Unable to update treatment centres")
			break
		case ActionTypes.PROFESSIONAL_ADD_FAILURE:
			toast.error("Unable to add professional user")
			break
		case ActionTypes.PROFESSIONAL_USER_UPDATE_RECEIVE:
			toast.success("User updated successfully")
			return state
		case ActionTypes.PROFESSIONAL_USER_UPDATE_FAILURE:
			toast.error("Unable to update user")
			break
		case ActionTypes.PROFESSIONAL_USER_UPDATE_NOTE_RECEIVE:
			return {
				...state,
				professional: {
					...state.professional,
					adminNotes: action.meta
				}
			}
	}
	return state
}

export default userReducer
