import { useEffect } from "react"
import usePrevious from "../../hooks/usePrevious"
import { zoneRegistry } from "mco-zone-configurations"

const ZoneIndicator = () => {
	const {
		settings: { cssClass, showWarning }
	} = zoneRegistry.get()
	const previousZoneClass: string = usePrevious(cssClass)

	useEffect(() => {
		if (previousZoneClass || previousZoneClass === cssClass) {
			return
		}
		if (previousZoneClass) {
			document.body.classList.remove(previousZoneClass)
		}
		if (showWarning) {
			document.body.classList.add(cssClass)
		}
	})
	return null
}

export default ZoneIndicator
