import { combineReducers } from "redux"
import ExtractState from "../../../models/ExtractState"
import assessmentsReducer from "./assessment/reducer"
import benchmarkOverdueReducer from "./benchmarkOverdue/reducer"

export interface CadoganExtractState {
	assessment: ExtractState
	benchmarkOverdue: ExtractState
}

const cadoganReducer = combineReducers({
	assessment: assessmentsReducer,
	benchmarkOverdue: benchmarkOverdueReducer
})

export default cadoganReducer
