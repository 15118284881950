import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"
import ExtractState from "../../../../models/ExtractState"

export interface PatientDetailsExtractState extends ExtractState {
	uploadErrors: string
	patientConditionIds: number[]
}

const initialSpirePatientDetails: PatientDetailsExtractState = {
	downloadInProgress: false,
	uploadErrors: "",
	patientConditionIds: []
}

function spirePatientDetailsReducer(
	state: PatientDetailsExtractState = initialSpirePatientDetails,
	action: any
): PatientDetailsExtractState {
	switch (action.type) {
		case ActionTypes.EXTRACTS_SPIRE_PATIENT_DETAILS_DOWNLOAD_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.EXTRACTS_SPIRE_PATIENT_DETAILS_DOWNLOAD_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_SPIRE_PATIENT_DETAILS_DOWNLOAD_FAILURE:
			toast.error("Unable to download Patient Details extract")
			return {
				...state,
				downloadInProgress: false
			}
	}
	return state
}

export default spirePatientDetailsReducer
