export const EXTRACTS_PHIN_DIGESTS_REQUEST = "EXTRACTS_PHIN_DIGESTS_REQUEST"
export const EXTRACTS_PHIN_DIGESTS_RECEIVE = "EXTRACTS_PHIN_DIGESTS_RECEIVE"
export const EXTRACTS_PHIN_DIGESTS_FAILURE = "EXTRACTS_PHIN_DIGESTS_FAILURE"

export const EXTRACTS_PHIN_SEND_TO_SFTP_REQUEST =
	"EXTRACTS_PHIN_SEND_TO_SFTP_REQUEST"
export const EXTRACTS_PHIN_SEND_TO_SFTP_RECEIVE =
	"EXTRACTS_PHIN_SEND_TO_SFTP_RECEIVE"
export const EXTRACTS_PHIN_SEND_TO_SFTP_FAILURE =
	"EXTRACTS_PHIN_SEND_TO_SFTP_FAILURE"

export const EXTRACTS_PHIN_UNDO_DIGEST_REQUEST =
	"EXTRACTS_PHIN_UNDO_DIGEST_REQUEST"
export const EXTRACTS_PHIN_UNDO_DIGEST_RECEIVE =
	"EXTRACTS_PHIN_UNDO_DIGEST_RECEIVE"
export const EXTRACTS_PHIN_UNDO_DIGEST_FAILURE =
	"EXTRACTS_PHIN_UNDO_DIGEST_FAILURE"
