export const GET_MEDICAL_SPECIALTIES_FOR_CONSULTANTS_REQUEST =
	"GET_MEDICAL_SPECIALTIES_FOR_CONSULTANTS_REQUEST"
export const GET_MEDICAL_SPECIALTIES_FOR_CONSULTANTS_RECEIVE =
	"GET_MEDICAL_SPECIALTIES_FOR_CONSULTANTS_RECEIVE"
export const GET_MEDICAL_SPECIALTIES_FOR_CONSULTANTS_FAILURE =
	"GET_MEDICAL_SPECIALTIES_FOR_CONSULTANTS_FAILURE"

export const GET_MEDICAL_SPECIALTIES_FOR_CONDITIONS_REQUEST =
	"GET_MEDICAL_SPECIALTIES_FOR_CONDITIONS_REQUEST"
export const GET_MEDICAL_SPECIALTIES_FOR_CONDITIONS_RECEIVE =
	"GET_MEDICAL_SPECIALTIES_FOR_CONDITIONS_RECEIVE"
export const GET_MEDICAL_SPECIALTIES_FOR_CONDITIONS_FAILURE =
	"GET_MEDICAL_SPECIALTIES_FOR_CONDITIONS_FAILURE"

export const GET_PROBLEM_AREAS_FOR_MEDICAL_SPECIALTY_REQUEST =
	"GET_PROBLEM_AREAS_FOR_MEDICAL_SPECIALTY_REQUEST"
export const GET_PROBLEM_AREAS_FOR_MEDICAL_SPECIALTY_RECEIVE =
	"GET_PROBLEM_AREAS_FOR_MEDICAL_SPECIALTY_RECEIVE"
export const GET_PROBLEM_AREAS_FOR_MEDICAL_SPECIALTY_FAILURE =
	"GET_PROBLEM_AREAS_FOR_MEDICAL_SPECIALTY_FAILURE"

export const CHANGE_CONDITION_REQUEST = "CHANGE_CONDITION_REQUEST"
export const CHANGE_CONDITION_RECEIVE = "CHANGE_CONDITION_RECEIVE"
export const CHANGE_CONDITION_FAILURE = "CHANGE_CONDITION_FAILURE"
