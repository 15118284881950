export const GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_REQUEST =
	"GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_REQUEST"
export const GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_RECEIVE =
	"GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_RECEIVE"
export const GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_FAILURE =
	"GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_FAILURE"

export const DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_REQUEST =
	"DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_REQUEST"
export const DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_RECEIVE =
	"DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_RECEIVE"
export const DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_FAILURE =
	"DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_FAILURE"

export const DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_REQUEST =
	"DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_REQUEST"
export const DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_RECEIVE =
	"DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_RECEIVE"
export const DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_FAILURE =
	"DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_FAILURE"

export const POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_REQUEST =
	"POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_REQUEST"
export const POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_RECEIVE =
	"POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_RECEIVE"
export const POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_FAILURE =
	"POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_FAILURE"

export const POST_INTEGRATIONS_KAFKA_PRODUCE_REQUEST =
	"POST_INTEGRATIONS_KAFKA_PRODUCE_REQUEST"
export const POST_INTEGRATIONS_KAFKA_PRODUCE_RECEIVE =
	"POST_INTEGRATIONS_KAFKA_PRODUCE_RECEIVE"
export const POST_INTEGRATIONS_KAFKA_PRODUCE_FAILURE =
	"POST_INTEGRATIONS_KAFKA_PRODUCE_FAILURE"

export const GET_INTEGRATIONS_KAFKA_QUERY_REQUEST =
	"GET_INTEGRATIONS_KAFKA_QUERY_REQUEST"
export const GET_INTEGRATIONS_KAFKA_QUERY_RECEIVE =
	"GET_INTEGRATIONS_KAFKA_QUERY_RECEIVE"
export const GET_INTEGRATIONS_KAFKA_QUERY_FAILURE =
	"GET_INTEGRATIONS_KAFKA_QUERY_FAILURE"

export const POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_REQUEST =
	"POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_REQUEST"
export const POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_RECEIVE =
	"POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_RECEIVE"
export const POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_FAILURE =
	"POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_FAILURE"
