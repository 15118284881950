import * as React from "react"
import { Alert, Container } from "react-bootstrap"
import { detect } from "detect-browser"

function BrowserWarning() {
	const browser = detect()

	if (!browser) {
		return null
	}

	switch (browser.name) {
		case "chrome":
		case "edge-chromium": {
			return null
		}
		default: {
			// Unsupported
			return (
				<Container>
					<Alert variant="warning" className="my-3">
						<h3>
							Radmin does not support {browser.name}. Please try
							again in a different browser such as chrome.
						</h3>
					</Alert>
				</Container>
			)
		}
	}
}

export default BrowserWarning
