import * as React from "react"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Icons from "./Icons"

function LoadingButton(props: any) {
	const { loading, ...buttonProps } = props
	return (
		<React.Fragment>
			{loading ? (
				<Button {...buttonProps} disabled>
					{props.children}
					<FontAwesomeIcon
						className="ml-1"
						icon={Icons.Spinner}
						pulse
					/>
				</Button>
			) : (
				<Button {...buttonProps} />
			)}
		</React.Fragment>
	)
}

LoadingButton.defaultProps = {
	loading: false
}

export default LoadingButton
