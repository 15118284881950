import fileDownload from "js-file-download"
import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import ExtractState from "../../../../models/ExtractState"

const initial: ExtractState = {
	downloadInProgress: false
}

function surveyAnswerExtractReducer(
	state: ExtractState = initial,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_URI_SURVEY_ANSWERS_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.EXTRACTS_URI_SURVEY_ANSWERS_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_URI_SURVEY_ANSWERS_FAILURE:
			toast.error(
				"Unable to download survey, question, answer URI extract"
			)
			return {
				...state,
				downloadInProgress: false
			}
	}
	return state
}

export default surveyAnswerExtractReducer
