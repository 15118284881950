
const tenantSpecificationsBySubDomain = {
	"aimovig": () => import("./Aimovig.json"),
	"aml": () => import("./AML.json"),
	"cadogan": () => import("./Cadogan.json"),
	"chelwest": () => import("./ChelWest.json"),
	"circle": () => import("./Circle.json"),
	"ggc": () => import("./GGC.json"),
	"hcauk": () => import("./HCA.json"),
	"hje": () => import("./HJE.json"),
	"horderhealthcare": () => import("./Horder Healthcare.json"),
	"lillyepromsbreastcancer": () => import("./Lilly Breast Cancer.json"),
	"lillyepromsibd": () => import("./Lilly IBD.json"),
	"meh": () => import("./Moorfields (MEH).json"),
	"mhg": () => import("./MHG.json"),
	"mph": () => import("./MPH.json"),
	"mpn10": () => import("./MPN10.json"),
	"nhsaa": () => import("./NHSAA.json"),
	"nhssussex": () => import("./NHS Sussex.json"),
	"nhsproms": () => import("./NHS Wales.json"),
	"trackingmyms": () => import("./Novartis MS.json"),
	"rcht": () => import("./RCHT.json"),
	"royalsurrey": () => import("./Royal Surrey.json"),
	"nhsscotland": () => import("./SCAN (NHS Scotland).json"),
	"spencerprivatehospitals": () => import("./Spencer.json"),
	"spire": () => import("./Spire.json"),
	"stjosephs": () => import("./St Joseph's.json"),
	"tlc": () => import("./TLC.json"),
	"trigger": () => import("./Trigger.json"),
}

export default tenantSpecificationsBySubDomain