import * as ActionTypes from "./types"

type SsoTokenResponse = any

interface TokenError {
	errorMessage: string
	values: any
}

export interface ClientState {
	sso: {
		authCode: string
		authCodeLoading: boolean
		authCodeFailure: boolean
		authCodeError: string
		token: SsoTokenResponse
		tokenLoading: boolean
		tokenFailure: boolean
		tokenError: TokenError
	}
}

const initialClientState: ClientState = {
	sso: {
		authCode: "",
		authCodeLoading: false,
		authCodeFailure: false,
		authCodeError: "",
		token: null as any,
		tokenLoading: false,
		tokenFailure: false,
		tokenError: null as unknown as TokenError
	}
}

function clientReducer(
	state: ClientState = initialClientState,
	action: any
): ClientState {
	switch (action.type) {
		case ActionTypes.CLIENT_PROFESSIONAL_SSO_AUTH_CODE_GET_REQUEST:
			return {
				...state,
				sso: {
					...state.sso,
					authCodeLoading: true,
					authCodeFailure: false,
					authCodeError: ""
				}
			}
		case ActionTypes.CLIENT_PROFESSIONAL_SSO_AUTH_CODE_GET_RECEIVE:
			return {
				...state,
				sso: {
					...state.sso,
					authCode: action.payload,
					authCodeLoading: false,
					authCodeFailure: false,
					...(action.payload === state.sso.authCode
						? null
						: {
								token: null as any,
								tokenLoading: false,
								tokenFailure: false,
								tokenError: null as unknown as TokenError
						  })
				}
			}
		case ActionTypes.CLIENT_PROFESSIONAL_SSO_AUTH_CODE_GET_FAILURE:
			return {
				...state,
				sso: {
					...state.sso,
					authCode: "",
					authCodeLoading: false,
					authCodeFailure: true,
					authCodeError: action.payload.response.Message,
					token: null as any,
					tokenLoading: false,
					tokenFailure: false,
					tokenError: null as unknown as TokenError
				}
			}
		case ActionTypes.CLIENT_PROFESSIONAL_SSO_AUTH_CODE_UPDATE:
			return {
				...state,
				sso: {
					...state.sso,
					authCode: action.authCode,
					token: null as any,
					tokenLoading: false,
					tokenFailure: false,
					tokenError: null as unknown as TokenError
				}
			}
		case ActionTypes.CLIENT_PROFESSIONAL_SSO_TOKEN_POST_REQUEST:
			return {
				...state,
				sso: {
					...state.sso,
					token: null as any,
					tokenLoading: true,
					tokenFailure: false,
					tokenError: null as unknown as TokenError
				}
			}
		case ActionTypes.CLIENT_PROFESSIONAL_SSO_TOKEN_POST_RECEIVE:
			return {
				...state,
				sso: {
					...state.sso,
					token: { ...action.payload },
					tokenLoading: false,
					tokenFailure: false
				}
			}
		case ActionTypes.CLIENT_PROFESSIONAL_SSO_TOKEN_POST_FAILURE:
			return {
				...state,
				sso: {
					...state.sso,
					token: null as any,
					tokenLoading: false,
					tokenFailure: true,
					tokenError: { ...action.payload.response }
				}
			}
	}
	return state
}

export default clientReducer
