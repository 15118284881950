import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"

export interface ConsultantsState {
	downloadInProgress: boolean
	uploadInProgress: boolean
	uploadErrors: string
}

const initialBulkAddConsultants: ConsultantsState = {
	downloadInProgress: false,
	uploadInProgress: false,
	uploadErrors: ""
}

function bulkAddConsultantsReducer(
	state: ConsultantsState = initialBulkAddConsultants,
	action: any
) {
	switch (action.type) {
		case ActionTypes.BULKADD_CONSULTANTS_TEMPLATE_DOWNLOAD_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.BULKADD_CONSULTANTS_TEMPLATE_DOWNLOAD_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.BULKADD_CONSULTANTS_TEMPLATE_DOWNLOAD_FAILURE:
			toast.error("Unable to download Bulk add consultant template")
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.BULKADD_CONSULTANTS_UPLOAD_REQUEST:
			return {
				...state,
				uploadInProgress: true,
				uploadErrors: ""
			}
		case ActionTypes.BULKADD_CONSULTANTS_UPLOAD_RECEIVE:
			toast.success("Successfully uploaded file and created consultants")
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: ""
			}
		case ActionTypes.BULKADD_CONSULTANTS_UPLOAD_FAILURE:
			toast.error("Unable to upload and add the consultants")
			const message =
				(action.payload &&
					action.payload.response &&
					action.payload.response.Message) ||
				"The upload failed but the response is empty. This could be because the server was not contacted at all. Check the Chrome Developer Tools console for the error message."
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: message
			}
	}
	return state
}

export default bulkAddConsultantsReducer
