import * as ActionTypes from "./types"
import { LAST_ONE_WINS_VALIDATION } from "../../../middleware/RequestValidationMiddleware"
import { SortingRule } from "react-table"
import { ProfessionalUser } from "../../../models/User"
import TENANTED_API_REQUEST from "../../../middleware/ApiRequestMiddleware"

export function getSearchProfessionalsFilters() {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: "/api/admin/professional/filters",
			method: "GET",
			types: [
				ActionTypes.PROFESSIONALS_SEARCH_FILTER_REQUEST,
				ActionTypes.PROFESSIONALS_SEARCH_FILTER_RECEIVE,
				ActionTypes.PROFESSIONALS_SEARCH_FILTER_FAILURE
			]
		}
	}
}

export function updateProfessionalsSearchFilterSelected(
	filterPipeline: string,
	checked: boolean
): any {
	return {
		type: ActionTypes.PROFESSIONALS_SEARCH_FILTER_UPDATE_SELECTED,
		filterPipeline,
		checked
	}
}

export function updateProfessionalsSearchToggleColumnsSelected(
	column: string,
	selected: boolean
) {
	return {
		type: ActionTypes.PROFESSIONALS_SEARCH_TOGGLE_COLUMNS_UPDATE_SELECTED,
		column: column,
		selected: selected
	}
}

export function updateProfessionalsSearchQuery(searchQuery: string): any {
	return {
		type: ActionTypes.PROFESSIONALS_SEARCH_FILTER_QUERY_UPDATE,
		searchQuery: searchQuery
	}
}

export function resetProfessionalsSearchState() {
	return {
		type: ActionTypes.PROFESSIONALS_SEARCH_RESET
	}
}

function doSearch(
	pageIndex: number,
	pageSize: number,
	filterPipeline: string,
	orderBy: string,
	desc: boolean,
	types: Array<string>
) {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: "/api/admin/professional/list",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				pageIndex,
				pageSize,
				filterPipeline,
				orderBy,
				desc
			}),
			method: "POST",
			[LAST_ONE_WINS_VALIDATION]: true,
			types: types
		}
	}
}

export function searchProfessionals(
	pageIndex: number,
	pageSize: number,
	filterPipeline: string,
	orderBy: string,
	desc: boolean
): any {
	const types = [
		ActionTypes.PROFESSIONALS_SEARCH_REQUEST,
		ActionTypes.PROFESSIONALS_SEARCH_RECEIVE,
		ActionTypes.PROFESSIONALS_SEARCH_FAILURE
	]
	return doSearch(pageIndex, pageSize, filterPipeline, orderBy, desc, types)
}

export function searchProfessionalsNoStateUpdate(
	pageIndex: number,
	pageSize: number,
	filterPipeline: string,
	orderBy: string,
	desc: boolean
): any {
	const types = [
		ActionTypes.PROFESSIONALS_NO_UPDATE_SEARCH_REQUEST,
		ActionTypes.PROFESSIONALS_NO_UPDATE_SEARCH_RECEIVE,
		ActionTypes.PROFESSIONALS_NO_UPDATE_SEARCH_FAILURE
	]
	return doSearch(pageIndex, pageSize, filterPipeline, orderBy, desc, types)
}

export function updatePageIndex(pageIndex: number) {
	return {
		type: ActionTypes.PROFESSIONALS_SEARCH_PAGE_INDEX_UPDATE,
		page: pageIndex
	}
}

export function updatePageSizeAndIndex(
	newPageSize: number,
	newPageIndex: number
) {
	return {
		type: ActionTypes.PROFESSIONALS_SEARCH_PAGE_Size_AND_INDEX_UPDATE,
		page: newPageIndex,
		pageSize: newPageSize
	}
}

export function updateSorted(newSorted: SortingRule<ProfessionalUser>[]) {
	return {
		type: ActionTypes.PROFESSIONALS_SEARCH_SORTED_UPDATE,
		sorted: newSorted
	}
}
