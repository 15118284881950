import * as ActionTypes from "./types"
import TENANTED_API_REQUEST from "../../middleware/ApiRequestMiddleware"

export function getBridgeRecords(): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/integrations/bridge/records`,
			method: "GET",
			types: [
				ActionTypes.GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_REQUEST,
				ActionTypes.GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_RECEIVE,
				ActionTypes.GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_FAILURE
			]
		}
	}
}

export function deleteAllBridgeRecords(): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/integrations/bridge/records`,
			method: "DELETE",
			types: [
				ActionTypes.DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_REQUEST,
				ActionTypes.DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_RECEIVE,
				ActionTypes.DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_FAILURE
			]
		}
	}
}

export function deleteBridgeRecord(bridgeActivityId: number): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/integrations/bridge/record/${bridgeActivityId}`,
			method: "DELETE",
			types: [
				ActionTypes.DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_REQUEST,
				ActionTypes.DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_RECEIVE,
				ActionTypes.DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_FAILURE
			]
		}
	}
}

export function importUnpackTransformAndMerge(file: File): any {
	let data = new FormData()
	data.append("file", file)

	return {
		[TENANTED_API_REQUEST]: {
			endpoint: "/api/admin/import/unpacktransformmerge",
			method: "POST",
			body: data,
			types: [
				ActionTypes.POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_REQUEST,
				ActionTypes.POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_RECEIVE,
				ActionTypes.POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_FAILURE
			]
		}
	}
}

export function sendToKafka(file: File): any {
	let data = new FormData()
	data.append("file", file)

	return {
		[TENANTED_API_REQUEST]: {
			endpoint: "/api/admin/integrations/kafka",
			method: "POST",
			body: data,
			types: [
				ActionTypes.POST_INTEGRATIONS_KAFKA_PRODUCE_REQUEST,
				ActionTypes.POST_INTEGRATIONS_KAFKA_PRODUCE_RECEIVE,
				ActionTypes.POST_INTEGRATIONS_KAFKA_PRODUCE_FAILURE
			]
		}
	}
}

export function getKafkaQuery(): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/integrations/kafka`,
			method: "GET",
			types: [
				ActionTypes.GET_INTEGRATIONS_KAFKA_QUERY_REQUEST,
				ActionTypes.GET_INTEGRATIONS_KAFKA_QUERY_RECEIVE,
				ActionTypes.GET_INTEGRATIONS_KAFKA_QUERY_FAILURE
			]
		}
	}
}

export function replayByOffset(offset: number): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/integrations/kafka/${offset}`,
			method: "POST",
			types: [
				ActionTypes.POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_REQUEST,
				ActionTypes.POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_RECEIVE,
				ActionTypes.POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_FAILURE
			]
		}
	}
}
