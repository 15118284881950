export const EXTRACTS_TLC_WEEKLY_DOWNLOAD_REQUEST =
	"EXTRACTS_TLC_WEEKLY_DOWNLOAD_REQUEST"
export const EXTRACTS_TLC_WEEKLY_DOWNLOAD_RECEIVE =
	"EXTRACTS_TLC_WEEKLY_DOWNLOAD_RECEIVE"
export const EXTRACTS_TLC_WEEKLY_DOWNLOAD_FAILURE =
	"EXTRACTS_TLC_WEEKLY_DOWNLOAD_FAILURE"

export const EXTRACTS_TLC_WEEKLY_SEND_TO_SFTP_REQUEST =
	"EXTRACTS_TLC_WEEKLY_SEND_TO_SFTP_REQUEST"
export const EXTRACTS_TLC_WEEKLY_SEND_TO_SFTP_RECEIVE =
	"EXTRACTS_TLC_WEEKLY_SEND_TO_SFTP_RECEIVE"
export const EXTRACTS_TLC_WEEKLY_SEND_TO_SFTP_FAILURE =
	"EXTRACTS_TLC_WEEKLY_SEND_TO_SFTP_FAILURE"
