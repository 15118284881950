import * as React from "react"
import { Container, Spinner } from "react-bootstrap"

const LoadingMessage = ({ children }: { children: any }) => {
	return (
		<Container fluid className="text-center my-3">
			<Spinner animation="border" role="status" className="large-spinner">
				<span className="sr-only">Loading...</span>
			</Spinner>
			<h1>{children}</h1>
		</Container>
	)
}

export default LoadingMessage
