import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import type {
	PatientConditionIssueSummary,
	PatientConditionIssueModel
} from "../../../models/PatientConditionIssue"
import type { IDictionary } from "../../../models/IDictionary"

export interface PmaIssuesState {
	filters: IDictionary
	loadingFilters: boolean
	issues: PatientConditionIssueSummary[]
	loadingIssues: boolean
	issue: PatientConditionIssueModel
	loadingIssue: boolean
}

const initialPmaIssues: PmaIssuesState = {
	filters: [],
	loadingFilters: false,
	issues: [],
	loadingIssues: false,
	issue: {} as PatientConditionIssueModel,
	loadingIssue: false
}

function pmaIssuesReducer(
	state: PmaIssuesState = initialPmaIssues,
	action: any
) {
	switch (action.type) {
		case ActionTypes.PATIENT_ISSUES_FILTERS_REQUEST:
			return {
				...state,
				filters: [],
				loadingFilters: true
			}
		case ActionTypes.PATIENT_ISSUES_FILTERS_RECEIVE:
			return {
				...state,
				filters: action.payload,
				loadingFilters: false
			}
		case ActionTypes.PATIENT_ISSUES_FILTERS_FAILURE:
			toast.error("Unable to retrieve PMAs with issues")
			return {
				...state,
				loadingFilters: false
			}
		case ActionTypes.PATIENTS_ISSUES_LIST_REQUEST:
			return {
				...state,
				issues: [],
				loadingIssues: true
			}
		case ActionTypes.PATIENTS_ISSUES_LIST_RECEIVE:
			return {
				...state,
				issues: action.payload,
				loadingIssues: false
			}
		case ActionTypes.PATIENTS_ISSUES_LIST_FAILURE:
			toast.error("Unable to retrieve PMAs with issues")
			return {
				...state,
				loadingIssues: false
			}
		case ActionTypes.PATIENTS_ISSUE_REQUEST:
			return {
				...state,
				loadingIssue: true
			}
		case ActionTypes.PATIENTS_ISSUE_RECEIVE:
			return {
				...state,
				loadingIssue: false,
				issue: action.payload
			}
		case ActionTypes.PATIENTS_ISSUE_FAILURE:
			toast.error("Unable to load PMA for investigation")
			return {
				...state,
				issue: {},
				loadingIssue: false
			}
	}
	return state
}

export default pmaIssuesReducer
