import { Tenant } from "mco-tenant-configurations"
import { TenantSpecification } from "mco-tenant-specifications"
import { createContext } from "react"

export type TreatmentCenter = {
	id: number // TODO: Remove usages of this in favour of the uuid - need to change relevant APIs simultaneously
	uuid: string
	name: string
	isMcoTest: boolean
}

export type RadminTenantEnvironment = {
	url: string
	dbName: string
	treatmentCenters: TreatmentCenter[]
	mostRecentAssessmentDate: string
	professionalSsoSettings: {
		isTestSsoEndpointEnabled: boolean
		secret: string
	}
	statistics: {
		patientSignupsThisWeek: number
		patientSignupsThisMonth: number
		loginsToday: number
		totalUsers: number
	}
}

export type RadminTenant = {
	configuration: Tenant
	specification: TenantSpecification
	environment: RadminTenantEnvironment
}

export type SetTenantSubDomain = (subDomain?: string) => void

export type RadminTenantContext = {
	tenantSubDomain?: string
	tenant?: RadminTenant
}

const TenantContext = createContext(undefined as unknown as RadminTenantContext)

export default TenantContext
