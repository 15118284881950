import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import ExtractState from "../../../../models/ExtractState"

const initialTlcWorsenedPatients: ExtractState = {
	downloadInProgress: false,
	sendToSftpInProgress: false
}

function tlcWorsenedPatientsReducer(
	state: ExtractState = initialTlcWorsenedPatients,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_TLC_WORSENED_PATIENTS_SEND_TO_SFTP_REQUEST:
			return {
				...state,
				sendToSftpInProgress: true
			}
		case ActionTypes.EXTRACTS_TLC_WORSENED_PATIENTS_SEND_TO_SFTP_RECEIVE:
			toast.success("TLC Worsened Patients extract sent to SFTP")
			return {
				...state,
				sendToSftpInProgress: false
			}
		case ActionTypes.EXTRACTS_TLC_WORSENED_PATIENTS_SEND_TO_SFTP_FAILURE:
			toast.error("Unable to send TLC Worsened Patients extract to SFTP")
			return {
				...state,
				sendToSftpInProgress: false
			}
	}
	return state
}

export default tlcWorsenedPatientsReducer
