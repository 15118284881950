import { combineReducers } from "redux"
import consultantsReducer, { ConsultantsState } from "./consultants/reducer"
import bulkAddSecretariesReducer, {
	SecretariesState
} from "./secretaries/reducer"

export interface BulkAddState {
	consultants: ConsultantsState
	secretaries: SecretariesState
}

const bulkAddReducer = combineReducers({
	consultants: consultantsReducer,
	secretaries: bulkAddSecretariesReducer
})

export default bulkAddReducer
