import * as ActionTypes from "./types"
import { toast } from "react-toastify"

export type MissingTenantPatientIdExtractState = {
	downloading: boolean
	uploading: boolean
	uploadErrors?: string
}

const initial: MissingTenantPatientIdExtractState = {
	downloading: false,
	uploading: false
}

function reducer(
	state: MissingTenantPatientIdExtractState = initial,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_CIRCLE_MISSING_TENANT_PATIENT_ID_EXTRACT_REQUEST:
			return {
				...state,
				downloading: true
			}
		case ActionTypes.EXTRACTS_CIRCLE_MISSING_TENANT_PATIENT_ID_EXTRACT_RECEIVE:
			toast.success("Started upload to SFTP")
			return {
				...state,
				downloading: false
			}
		case ActionTypes.EXTRACTS_CIRCLE_MISSING_TENANT_PATIENT_ID_EXTRACT_FAILURE:
			toast.error("Unable to download extract")
			return {
				...state,
				downloading: false
			}
		case ActionTypes.EXTRACTS_CIRCLE_UPLOAD_MISSING_TENANT_PATIENT_ID_REQUEST:
			return {
				...state,
				uploading: true,
				uploadErrors: undefined
			}
		case ActionTypes.EXTRACTS_CIRCLE_UPLOAD_MISSING_TENANT_PATIENT_ID_RECEIVE:
			toast.success("Started upload of missing client patient ids")
			return {
				...state,
				uploading: false
			}
		case ActionTypes.EXTRACTS_CIRCLE_UPLOAD_MISSING_TENANT_PATIENT_ID_FAILURE:
			toast.error("Unable to upload and process all records")
			return {
				...state,
				uploading: false,
				uploadErrors: action.payload.response.Message
			}
	}
	return state
}

export default reducer
