import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"

export interface EpisodeNumberExtractState {
	uploadInProgress: boolean
	uploadErrors: string
	downloadInProgress: boolean
}

const initialSpireEpisodeNumber: EpisodeNumberExtractState = {
	uploadInProgress: false,
	uploadErrors: "",
	downloadInProgress: false
}

function spireEpisodeNumberReducer(
	state: EpisodeNumberExtractState = initialSpireEpisodeNumber,
	action: any
): EpisodeNumberExtractState {
	switch (action.type) {
		case ActionTypes.EXTRACTS_SPIRE_MISSINGEPISODENUMBER_DOWNLOAD_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.EXTRACTS_SPIRE_MISSINGEPISODENUMBER_DOWNLOAD_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_SPIRE_MISSINGEPISODENUMBER_DOWNLOAD_FAILURE:
			toast.error(
				"Unable to download Spire Missing Episode Number extract"
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_SPIRE_EPISODENUMBER_UPLOAD_REQUEST: {
			return {
				...state,
				uploadInProgress: true,
				uploadErrors: ""
			}
		}
		case ActionTypes.EXTRACTS_SPIRE_EPISODENUMBER_UPLOAD_RECEIVE: {
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: ""
			}
		}
		case ActionTypes.EXTRACTS_SPIRE_EPISODENUMBER_UPLOAD_FAILURE: {
			toast.error(
				"Unable to upload and process the Episode Number extract"
			)
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: action.payload.response.Message
			}
		}
	}
	return state
}

export default spireEpisodeNumberReducer
