import { useSelector } from "react-redux"
import { State } from "../state/reducer"
import { DateTime } from "luxon"

const useToday = () => {
	const overriddenDate = useSelector(
		(state: State) => state.environment.overriddenDate
	)

	if (overriddenDate) {
		return DateTime.fromISO(overriddenDate)
	}

	return DateTime.now()
}

export default useToday
