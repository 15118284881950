import { User } from "@auth0/auth0-react"
import * as ActionTypes from "./types"

interface UserAppMetadata {
	permittedZones: string[]
	isDeveloper: boolean
}

const appMetadataUri = "http://myclinicaloutcomes.com/app_metadata"
export class AdminUser extends User {
	public ["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]?: string
	public appMetadata?: UserAppMetadata
}

export interface AuthState {
	isAuthenticated: boolean
	user?: AdminUser
	accessToken?: string
	expiresAt?: number
}

const initial: AuthState = {
	isAuthenticated: false
}

function parseJwt(token: string) {
	var base64Url = token.split(".")[1]
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join("")
	)

	return JSON.parse(jsonPayload)
}

function authReducer(state: AuthState = initial, action: any): AuthState {
	switch (action.type) {
		case ActionTypes.FINISH_AUTHENTICATE:
			const parsedJwt = parseJwt(action.accessToken)
			const user: AdminUser = {
				...action.user,
				appMetadata: parsedJwt[appMetadataUri]
			}
			return {
				...state,
				isAuthenticated: true,
				user,
				accessToken: action.accessToken,
				expiresAt: +parsedJwt.exp
			}
		case ActionTypes.LOGOUT:
			return {
				...state,
				isAuthenticated: false,
				user: undefined,
				accessToken: undefined,
				expiresAt: undefined
			}
	}

	return state
}

export default authReducer
