export const PATIENT_ISSUES_FILTERS_REQUEST = "PATIENT_ISSUES_FILTERS_REQUEST"
export const PATIENT_ISSUES_FILTERS_RECEIVE = "PATIENT_ISSUES_FILTERS_RECEIVE"
export const PATIENT_ISSUES_FILTERS_FAILURE = "PATIENT_ISSUES_FILTERS_FAILURE"

export const PATIENTS_ISSUES_LIST_REQUEST = "PATIENTS_ISSUES_LIST_REQUEST"
export const PATIENTS_ISSUES_LIST_RECEIVE = "PATIENTS_ISSUES_LIST_RECEIVE"
export const PATIENTS_ISSUES_LIST_FAILURE = "PATIENTS_ISSUES_LIST_FAILURE"

export const PATIENTS_ISSUE_REQUEST = "PATIENTS_ISSUE_REQUEST"
export const PATIENTS_ISSUE_RECEIVE = "PATIENTS_ISSUE_RECEIVE"
export const PATIENTS_ISSUE_FAILURE = "PATIENTS_ISSUE_FAILURE"

export const PATIENTS_ISSUE_MARKASOK_REQUEST = "PATIENTS_ISSUE_MARKASOK_REQUEST"
export const PATIENTS_ISSUE_MARKASOK_RECEIVE = "PATIENTS_ISSUE_MARKASOK_RECEIVE"
export const PATIENTS_ISSUE_MARKASOK_FAILURE = "PATIENTS_ISSUE_MARKASOK_FAILURE"

export const PATIENTS_ISSUE_FIX_REQUEST = "PATIENTS_ISSUE_FIX_REQUEST"
export const PATIENTS_ISSUE_FIX_RECEIVE = "PATIENTS_ISSUE_FIX_RECEIVE"
export const PATIENTS_ISSUE_FIX_FAILURE = "PATIENTS_ISSUE_FIX_FAILURE"
