import { createRoot } from "react-dom/client"
import "bootstrap/dist/css/bootstrap.css"
import { createStore, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import reducer, { State } from "./state/reducer"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import thunkMiddleware from "redux-thunk"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { apiMiddleware } from "redux-api-middleware"
import { applyLastOneWinsValidationMiddleware } from "./middleware/RequestValidationMiddleware"
import { applyTenantedApiRequestMiddleware } from "./middleware/ApiRequestMiddleware"
import auth0Options from "./config/Auth0Config"
import { Auth0Provider } from "@auth0/auth0-react"
import { BrowserRouter } from "react-router-dom"

const loadState = () => {
	try {
		const serializedState = localStorage.getItem("state")
		if (serializedState === null) {
			return undefined
		}
		let savedState = JSON.parse(serializedState)

		savedState.auth.isFetching = false
		return savedState
	} catch (err) {
		return undefined
	}
}

const saveState = (state: any) => {
	try {
		const stateToSave = { ...state }
		const serializedState = JSON.stringify(stateToSave)
		localStorage.setItem("state", serializedState)
	} catch {
		// ignore write errors
	}
}

const initialState = loadState()
let createStoreWithMiddleware = applyMiddleware(
	applyTenantedApiRequestMiddleware,
	applyLastOneWinsValidationMiddleware,
	apiMiddleware,
	thunkMiddleware
)(createStore)
const store = createStoreWithMiddleware(reducer(), initialState)

store.subscribe(() => {
	let state = store.getState() as unknown as Partial<State>
	saveState({
		auth: state.auth,
		environment: {
			tenants: {}
		}
	})
})

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<Auth0Provider
				domain={auth0Options.domain}
				clientId={auth0Options.clientID}
				authorizationParams={{
					redirect_uri: window.location.origin,
					audience: `https://myclinicaloutcomes.com`,
					scope: "profile email read:current_user"
				}}
			>
				<ToastContainer />
				<App />
			</Auth0Provider>
		</BrowserRouter>
	</Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
