import { Col, Container, ListGroup, Row } from "react-bootstrap"
import { TenantButton } from "./TenantButton"
import { useTenantOptions } from "./TenantHooks"
import { useNickname } from "../../hooks/useNickname"

function TenantSelector() {
	const tenantOptions = useTenantOptions()

	const nickname = useNickname()

	return (
		<Container>
			<Row className="my-3">
				<Col>
					<h1>MCO Tenants</h1>
					<p>Hi {nickname}</p>
					<ListGroup>
						{tenantOptions.map((tenantOption) => (
							<TenantButton
								key={tenantOption.subDomain}
								tenantOption={tenantOption}
							/>
						))}
					</ListGroup>
				</Col>
			</Row>
		</Container>
	)
}

export default TenantSelector
