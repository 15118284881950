import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"
import ExtractState from "../../../models/ExtractState"

const initialScanExtract: ExtractState = {
	downloadInProgress: false
}

function scanExtractReducer(
	state: ExtractState = initialScanExtract,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_SCAN_DOWNLOAD_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.EXTRACTS_SCAN_DOWNLOAD_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_SCAN_DOWNLOAD_FAILURE:
			toast.error("Unable to download SCAN extract")
			return {
				...state,
				downloadInProgress: false
			}
	}
	return state
}

export default scanExtractReducer
