import { combineReducers } from "redux"
import nhsReducer from "./nhsExtract/reducer"
import nhsNumberReducer, { NhsWalesNumberExtractState } from "./nhsNumber/reducer"
import ExtractState from "../../../models/ExtractState"

export interface NhsWalesExtractState {
    nhsExtract: ExtractState
    nhsNumberExtract: NhsWalesNumberExtractState
}

const nhsWalesReducer = combineReducers({
    nhsExtract: nhsReducer,
    nhsNumberExtract: nhsNumberReducer
})

export default nhsWalesReducer
