import { useAuth0 } from "@auth0/auth0-react"
import { useSelector } from "react-redux"
import { State } from "../state/reducer"

export function useIsAuthenticated() {
	const { isAuthenticated, expiresAt } = useSelector(
		(state: State) => state.auth
	)
	return (
		isAuthenticated && expiresAt && new Date(expiresAt * 1000) > new Date()
	)
}

export function useIsFetching() {
	return useAuth0().isLoading
}
