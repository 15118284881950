import * as ActionTypes from "./types"

export interface UpdatePatientConditionModel {
	patientConditionId: number
	clientConditionId: string | null
	episodeNumber: string | null
}

export interface UpdatePatientConditionState {
	submitting: boolean
}

const initial: UpdatePatientConditionState = {
	submitting: false
}

function reducer(
	state: UpdatePatientConditionState = initial,
	action: any
): UpdatePatientConditionState {
	switch (action.type) {
		case ActionTypes.UPDATE_PATIENT_CONDITION_REQUEST:
			return {
				...state,
				submitting: true
			}
		case ActionTypes.UPDATE_PATIENT_CONDITION_RECEIVE:
			return {
				...state,
				submitting: false
			}
		case ActionTypes.UPDATE_PATIENT_CONDITION_FAILURE:
			return {
				...state,
				submitting: false
			}
	}
	return state
}

export default reducer
