export const BULKADD_SECRETARIES_TEMPLATE_DOWNLOAD_REQUEST =
	"BULKADD_SECRETARIES_TEMPLATE_DOWNLOAD_REQUEST"
export const BULKADD_SECRETARIES_TEMPLATE_DOWNLOAD_RECEIVE =
	"BULKADD_SECRETARIES_TEMPLATE_DOWNLOAD_RECEIVE"
export const BULKADD_SECRETARIES_TEMPLATE_DOWNLOAD_FAILURE =
	"BULKADD_SECRETARIES_TEMPLATE_DOWNLOAD_FAILURE"

export const BULKADD_SECRETARIES_UPLOAD_REQUEST =
	"BULKADD_SECRETARIES_UPLOAD_REQUEST"
export const BULKADD_SECRETARIES_UPLOAD_RECEIVE =
	"BULKADD_SECRETARIES_UPLOAD_RECEIVE"
export const BULKADD_SECRETARIES_UPLOAD_FAILURE =
	"BULKADD_SECRETARIES_UPLOAD_FAILURE"
