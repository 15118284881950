import { combineReducers } from "redux"
import searchProfessionalsReducer from "./search"
import userReducer from "./user"
import bulkAddReducer, { BulkAddState } from "./bulkadd/reducer"
import { SearchState } from "../../state/professionals/search/reducer"
import { UserState } from "./user/reducer"

export interface ProfessionalsState {
	search: SearchState
	user: UserState
	bulkAdd: BulkAddState
}

const professionalsReducer = combineReducers({
	search: searchProfessionalsReducer,
	user: userReducer,
	bulkAdd: bulkAddReducer
})

export default professionalsReducer
