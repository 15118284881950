import { combineReducers } from "redux"
import weeklyReducer from "./weekly/reducer"
import nhsNumberReducer from "./nhsNumber/reducer"
import patientDetailsReducer, {
	PatientDetailsExtractState
} from "./patientDetails/reducer"
import ExtractState from "../../../models/ExtractState"
import episodeNumberReducer, {
	EpisodeNumberExtractState
} from "./episodeNumber/reducer"
import worsenedPatientsReducer from "./worsenedPatients/reducer"
import adHocReducer from "./adHoc/reducer"

export interface SpireExtractState {
	weekly: ExtractState
	nhsNumber: ExtractState
	patientDetails: PatientDetailsExtractState
	episodeNumber: EpisodeNumberExtractState
	worsenedPatients: ExtractState
	adHoc: ExtractState
}

const spireReducer = combineReducers({
	weekly: weeklyReducer,
	nhsNumber: nhsNumberReducer,
	patientDetails: patientDetailsReducer,
	episodeNumber: episodeNumberReducer,
	worsenedPatients: worsenedPatientsReducer,
	adHoc: adHocReducer
})

export default spireReducer
