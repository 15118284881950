// Duplicated in Front end
import { RSAA } from "redux-api-middleware"
import { combineReducers, ReducersMapObject, Reducer } from "redux"

export const LAST_ONE_WINS_VALIDATION = "@@mco_last_one_wins_validation"

const LAST_ONE_WINS_VALIDATION_META = "@@mco_last_one_wins_validation_meta"
const LAST_ONE_WINS_VALIDATION_STATE = "@@mco_last_one_wins_validation_state"

const LAST_REQUEST_UPDATE_FOR_VALIDATION: string =
	"LAST_REQUEST_UPDATE_FOR_VALIDATION"

const isString = (value: string) => typeof value === "string"

const hasLastOneWinsValidation = (action: any) =>
	action[RSAA] && action[RSAA][LAST_ONE_WINS_VALIDATION]

export const applyLastOneWinsValidationMiddleware =
	(store: any) => (next: any) => (action: any) => {
		if (!hasLastOneWinsValidation(action)) {
			return next(action)
		}

		// redux-api-middleware will not work if there are unexpected properties on the RSAA
		delete action[RSAA][LAST_ONE_WINS_VALIDATION]

		const date = new Date()

		// TODO: WABA: Add in some error handling
		let receiveType = action[RSAA].types[1]
		if (isString(receiveType)) {
			receiveType = {
				type: receiveType
			}
		}
		receiveType.meta = receiveType.meta || {}
		receiveType.meta[LAST_ONE_WINS_VALIDATION_META] = {
			date: date
		}
		action[RSAA].types[1] = receiveType

		let errorType = action[RSAA].types[2]
		if (isString(errorType)) {
			errorType = {
				type: errorType
			}
		}
		errorType.meta = errorType.meta || {}
		errorType.meta[LAST_ONE_WINS_VALIDATION_META] = {
			date,
			error: true
		}
		action[RSAA].types[2] = errorType

		store.dispatch({
			type: LAST_REQUEST_UPDATE_FOR_VALIDATION,
			key: receiveType.type,
			date: date
		})
		store.dispatch({
			type: LAST_REQUEST_UPDATE_FOR_VALIDATION,
			key: errorType.type,
			date: date
		})

		return next(action)
	}

const lastOneWinsValidationReducer = (state: any = {}, action: any) => {
	switch (action.type) {
		case LAST_REQUEST_UPDATE_FOR_VALIDATION:
			return {
				...state,
				[action.key]: action.date
			}
	}
	return state
}

const isValidRequest = (state: any, action: any) => {
	const validationRequestDate =
		action.meta &&
		action.meta[LAST_ONE_WINS_VALIDATION_META] &&
		action.meta[LAST_ONE_WINS_VALIDATION_META].date
	if (!validationRequestDate) {
		return true
	}

	const validValue = state[LAST_ONE_WINS_VALIDATION_STATE][action.type]

	const valid = validValue === validationRequestDate

	if (!valid && action.meta[LAST_ONE_WINS_VALIDATION_META].error) {
		console.log("An earlier request returned an error.")
		console.log("Action: ")
		console.log(action)
	}

	return valid
}

export function combineReducersWithRequestValidation<S>(
	reducers: ReducersMapObject<S, any>
): Reducer<S> {
	const reducersWithValidation: ReducersMapObject<S, any> = {
		...reducers,
		[LAST_ONE_WINS_VALIDATION_STATE]: lastOneWinsValidationReducer
	}

	const combinedReducerWithValidation = combineReducers(
		reducersWithValidation
	)

	return (state: any, action: any) => {
		if (!isValidRequest(state, action)) {
			return state
		}

		return combinedReducerWithValidation(state, action)
	}
}
