import type { TenantSpecification } from "./TenantSpecification"
import tenantSpecificationsBySubDomain from "./t4/tenantSpecificationsBySubDomain"
import { retry } from "mco-typescript-core"
export type { TenantSpecification } from "./TenantSpecification"
export type { TenantConditionSpecification } from "./TenantConditionSpecification"

type TenantImporter = () => Promise<{ default: TenantSpecification }>

export type TenantSubDomain = keyof typeof tenantSpecificationsBySubDomain

type TenantSubDomainDictionary = {
	[key in TenantSubDomain]: TenantImporter
}

class TenantRegistry {
	public allSubDomains: TenantSubDomain[]

	constructor(private tenantsBySubDomain: TenantSubDomainDictionary) {
		this.allSubDomains = Object.keys(this.tenantsBySubDomain).map(
			(subDomain) => subDomain as TenantSubDomain
		)
	}

	public async getTenantBySubDomain(subDomain: string) {
		if (!this.isSubDomainValid(subDomain)) {
			throw new Error(
				`Can not retrieve tenant by subdomain: ${subDomain}`
			)
		}

		const tenant = (await retry(() =>
			this.tenantsBySubDomain[subDomain as TenantSubDomain]()
		)) as { default: TenantSpecification }
		return tenant.default
	}

	public async getAllTenants() {
		const allTenantPromises = this.allSubDomains.map((subDomain) => {
			return this.tenantsBySubDomain[subDomain]().then((t) => t.default)
		})
		const allTenants = await Promise.all(allTenantPromises)
		return allTenants
	}

	public isSubDomainValid(value: string) {
		return !!this.allSubDomains.find((subDomain) => subDomain === value)
	}
}

const tenantRegistry = new TenantRegistry(tenantSpecificationsBySubDomain)
export default tenantRegistry
