import * as ActionTypes from "./types"
import { toast } from "react-toastify"

const initialCadoganExtract = {}

function cadoganExtractReducer(state = initialCadoganExtract, action: any) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_CADOGAN_BENCHMARK_OVERDUE_RECEIVE:
			toast.success("Sending to SFTP. Check Slack for progress")
			return state
		case ActionTypes.EXTRACTS_CADOGAN_BENCHMARK_OVERDUE_FAILURE:
			toast.error(
				"Unable to send Cadogan Benchmark Overdue extract to SFTP"
			)
			return state
	}
	return state
}

export default cadoganExtractReducer
