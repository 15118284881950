export const BULKADD_CONSULTANTS_TEMPLATE_DOWNLOAD_REQUEST =
	"BULKADD_CONSULTANTS_TEMPLATE_DOWNLOAD_REQUEST"
export const BULKADD_CONSULTANTS_TEMPLATE_DOWNLOAD_RECEIVE =
	"BULKADD_CONSULTANTS_TEMPLATE_DOWNLOAD_RECEIVE"
export const BULKADD_CONSULTANTS_TEMPLATE_DOWNLOAD_FAILURE =
	"BULKADD_CONSULTANTS_TEMPLATE_DOWNLOAD_FAILURE"

export const BULKADD_CONSULTANTS_UPLOAD_REQUEST =
	"BULKADD_CONSULTANTS_UPLOAD_REQUEST"
export const BULKADD_CONSULTANTS_UPLOAD_RECEIVE =
	"BULKADD_CONSULTANTS_UPLOAD_RECEIVE"
export const BULKADD_CONSULTANTS_UPLOAD_FAILURE =
	"BULKADD_CONSULTANTS_UPLOAD_FAILURE"
