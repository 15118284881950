import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"

export interface SecretariesState {
	downloadInProgress: boolean
	uploadInProgress: boolean
	uploadErrors: string
}

const initialBulkAddSecretaries: SecretariesState = {
	downloadInProgress: false,
	uploadInProgress: false,
	uploadErrors: ""
}

function bulkAddSecretariesReducer(
	state: SecretariesState = initialBulkAddSecretaries,
	action: any
) {
	switch (action.type) {
		case ActionTypes.BULKADD_SECRETARIES_TEMPLATE_DOWNLOAD_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.BULKADD_SECRETARIES_TEMPLATE_DOWNLOAD_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.BULKADD_SECRETARIES_TEMPLATE_DOWNLOAD_FAILURE:
			toast.error("Unable to download Bulk add consultant template")
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.BULKADD_SECRETARIES_UPLOAD_REQUEST:
			return {
				...state,
				uploadInProgress: true,
				uploadErrors: ""
			}
		case ActionTypes.BULKADD_SECRETARIES_UPLOAD_RECEIVE:
			toast.success("Successfully uploaded file and created secretaries")
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: ""
			}
		case ActionTypes.BULKADD_SECRETARIES_UPLOAD_FAILURE:
			toast.error("Unable to upload and add the secretaries")
			const message =
				(action.payload &&
					action.payload.response &&
					action.payload.response.Message) ||
				"An unknown error occurred uploading the file"

			return {
				...state,
				uploadInProgress: false,
				uploadErrors: message
			}
	}
	return state
}

export default bulkAddSecretariesReducer
